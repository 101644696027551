import { Injectable } from '@angular/core';
import {io} from 'socket.io-client';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
 private socket; //socket that connects to our socket.io server

  constructor(  private _authenticationService: AuthenticationService,) {}
  connect(): Subject<MessageEvent> {
    this.socket = io(environment.apiSocketUrl);
    let subject = new Subject<MessageEvent>();
    const currentUser = this._authenticationService.currentUserValue;
    
    this.socket.on('connect', () => {
      this.socket.emit('userId', currentUser._id);
    });

    this.socket.emit('setUserId', 76767);


    return subject;
  }
  



  updateUserSocket() {
  const userId = '12345';
  this.socket.emit("userId", userId);
  this.socket.on("notification", (data) => {
      console.log(data);
    });
  }


  sendMessage(msg) {
    this.socket.emit('message', msg);
}


}