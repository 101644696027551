import { CoreMenu } from '@core/types';


export const menu: CoreMenu[] = [
///-----
 // Apps & Pages
//  {

//   id: 'whatdoyouwantlearn',
//   title: 'what do you want to learn',
//   translate: 'MENU.HOME.TEST',
//   type: 'collapsible',
//   icon: 'book-open',


//   children: [
 

//     {
//       id: 'pages',
//       title: 'Pages',
//       translate: 'MENU.HOME.MATH',
//       type: 'collapsible',

//       children: [
    
//         {
//           id: 'account-settings',
//           title: 'Account Settings',
//           translate: 'MENU.HOME.ENGLISH',
//           type: 'item',
//           icon: 'circle',
//           url: 'courses/latest-courses'
//         },
//         {
//           id: 'account-settings',
//           title: 'Account Settings',
//           translate: 'MENU.HOME.MATH',
//           type: 'item',
//           icon: 'circle',
//           url: 'courses/latest-cm'
//         },
//       ]
//     }
 
//   ]
// },
// User Interface
///----
    // what do you want to learn
  
    // Apps & Pages
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'fortesttaker',
    title: 'For Test Takers',
    translate: 'MENU.HOME.FORTESTTAKER',
    type: 'item',
    icon: 'book-open',
    url: 'pages/home/about-us'
  },
  {
    id: 'fororganisations',
    title: 'For Organisations',
    translate: 'MENU.HOME.FORORG',
    type: 'item',
    icon: 'sidebar',
    url: 'pages/home/about-us'
  },
  {
    id: 'bookatest',
    title: 'Book a test',
    translate: 'MENU.HOME.BOOKTEST',
    type: 'item',
    icon: 'calendar',
    url: 'for-test-takers/book-a-test'
  },


  
  {
    id: 'aboutus',
    title: 'aboutus',
    translate: 'MENU.HOME.ABOUTUS',
    type: 'item',
    icon: 'info',
    url: 'pages/home/about-us'
  },


  
  {
    id: 'contactus',
    title: 'contactus',
    translate: 'MENU.HOME.CONTACTUS',
    type: 'item',
    icon: 'mail',
    url: 'pages/home/contact-us'
  },

  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.HOME.DASHBOARD',
    icon: 'box',
    type: 'item',
    role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
    url: 'admin/categories-list'
  },
 

];
