import { Package } from "./package";
import { Program } from "./program";
import { Role } from "./role";
export class User {
  id?: string;

  _id?: string;

  name?: string;
  email?: string;
  password?: string;
  photo?: string;
  mobile?: string;
  token?: string;
  lang?: string;
  role?: Role;
  dob?: string;
  bio?: string;
  gender?: string;
  country?: string;
  program?: Program;
  package?: Package;
  avatar?: string;
  avatar_path?: string;
  isActive?: boolean;
  schoolConfirmed?: boolean;
  school?: string;
  grades?:Array<Object>;
  categories?:Array<Object>;
  address?:Array<Object>;
  general?:Array<Object>;
  social?:Array<Object>;
  notification?:Array<Object>;
  emailConfirmed?: boolean;


}
