export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: {
        HOME: 'Home',
        DASHBOARD: 'Dashboard',
        ABOUTUS: 'About Us',
        FORTESTTAKER: 'For Test Takers',
        FORORG: 'For Organisations',
        BOOKTEST: 'Book a test',
        TERMSCONDITIONS:'Terms and conditions',
        CONTACTUS: 'Contact Us',
        MYLEARNING: 'My Learning',
        WHATDOYOUWANTLEARN: 'what do you want to learn?',
        MATH: 'Math',
        ENGLISH: 'English',
        TEST:'Test',

      },
      ADMIN: {
        CATEGORIES:'Categories',
        faculties:'Faculties',
        students:'Students',
        instructors:'Instructors',
        packages:'Packages',
        courses:'Courses',
        levels:'Levels',
      }, 


    },
    //-------- END
  }
};
