import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ToastrModule } from 'ngx-toastr'; // For auth after login toast
import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';


import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CsvModule } from '@ctrl/ngx-csv';

import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { LandingPageComponent } from './layout/pages/landing/landing-page.component';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { JwtInterceptor } from './auth/helpers';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccountSettingsService } from './services/account-settings.service';

const appRoutes: Routes = [
  
  {
    path: 'settings',
    loadChildren: () => import('./layout/pages/settings.module')
    .then(m => m.SettingsModule),
    canActivate: [AuthGuard]
  },
  
  {
    path: 'home',
    component: LandingPageComponent
  },

  {
    path: 'admin',
    loadChildren: () => import('./layout/pages/admin/admin.module')
    .then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'for-test-takers',
    loadChildren: () => import('./layout/pages/test-takers/test-takers.module')
    .then(m => m.TestTakersModule)
  },
  
  {
    path: 'pages',
    loadChildren: () => import('./layout/pages/pages.module')
    .then(m => m.PagesModule)
  },

 
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent
  ],
  imports: [
 
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),
    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),
    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    SwiperModule,
    // App modules
    LayoutModule,
    ContentHeaderModule,
    CardSnippetModule,
    NgxDatatableModule,
    CsvModule,
    FormsModule


   
  ],
 
providers:[AccountSettingsService,TranslatePipe,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  }
],
  bootstrap: [AppComponent],

  exports: [
  ]
})
export class AppModule {}
