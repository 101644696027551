<!-- Create a template reference to the outlet directive with the template variable assignment #outlet="outlet".
With this reference to the outlet directive, we can get the information of when the router outlet is active to trigger our animations. -->
<div *ngIf="currentUser && currentUser.school != null && !currentUser.schoolConfirmed" class="css-i96vhu">
  <div class="css-1lti7l5">

    <span class="nessie-text css-1ty5xfy"><span>

      {{'school_setting_incomplete'|translate}}</span></span>
    <div class="css-m3cpt4"></div>
    <div class="css-l5xv05">
      <a class="css-1ylt8zb" target="_blank"
        href="https://classdojo.zendesk.com/hc/en-us/requests/new?ticket_form_id=7296547234829"><span
          class="nessie-text css-pl61a4"><span>{{'get_verified'|translate}}</span></span></a>
        </div>
  </div>
  <div class="css-1il30g3">
    
    
    <!-- <button class="css-1kbftqd"><svg width="1em" height="1em" viewBox="0 0 24 24"
        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M12 9.172l4.243-4.243a2 2 0 112.828 2.828L14.828 12l4.243 4.243a2 2 0 01-2.828 2.828L12 14.828l-4.243 4.243a2 2 0 01-2.828-2.828L9.172 12 4.929 7.757A2 2 0 117.757 4.93L12 9.172z">
        </path>
      </svg>
    </button> -->
    
    </div>
</div>


<div  [@zoomIn]="zoomIn(outlet)" [@fadeInLeft]="fadeInLeft(outlet)" [@fadeIn]="fadeIn(outlet)">
  <router-outlet #outlet="outlet">
   
    
    

</router-outlet>
</div>
