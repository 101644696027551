
<div class="navbar-container d-flex content iet_background">

    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->
   <!-- Language -->
   <div class="bookmark-wrapper d-flex align-items-center">
    <img src="/assets/images/logo/logo-iet.png" class="iet-logo" width="30%">
    </div>


   <!-- <div class="bookmark-wrapper d-flex align-items-center">
   <ul  class="nav navbar-nav align-items-center ml-auto">
  <li ngbDropdown  class="'nav-item dropdown dropdown-language">
  <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
    <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
    ><span [ngClass]="isRTL? 'selected-language text-margin-rtl font-rtl' : 'selected-language'"
>{{ languageOptions[_translateService.currentLang].title }}</span></a>

<div ngbDropdownMenu aria-labelledby="dropdown-flag" 
  [ngClass]="isRTL? 'text-align-rtl font-rtl' : ''">
 
    <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem 
    (click)="setLanguage(lang)">
      <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i>
     <span [ngClass]="isRTL? 'text-margin-rtl font-rtl' : ''"> {{ languageOptions[lang].title }}</span> 
    </a>
  </div>
  </li>
</ul>
</div> -->

<div id="logo"></div>

  <ul [ngClass]="isRTL ? 'nav navbar-nav navbar-user-rtl' : 'nav navbar-nav align-items-center ml-auto'">

    <app-navbar-notification *ngIf="this.currentUser"></app-navbar-notification>
    <!--/ Notification -->
    <ng-container *ngIf="!this.currentUser">
      <span  [ngClass]="isRTL ? 'nav navbar-nav bookmark-icons ltr-layout font-rtl' : 'nav navbar-nav bookmark-icons'">
   
        <div class="bookmark-wrapper d-flex align-items-center" style="height: 40px;width: 265px;margin-right: -140px;">
          <img src="/assets/images/logo/ukm-logo.png" class="waves-effect ukm-logo" width="40%">
          </div>
      
      
      <!-- <a type="button" [routerLink]="['/pages/authentication/login']" class=" mr-50  btn btn-outline-dark waves-effect">{{ 'login' | translate }}</a> -->
      <!-- <a type="button" [routerLink]="['/pages/authentication/register']" class="btn btn-gradient-dark" style="color:white">{{ 'signup' | translate }}</a> -->
      </span>
    </ng-container>
    <!-- User Dropdown -->
    <li   *ngIf="this.currentUser" ngbDropdown class="nav-item dropdown-user">
      <a 
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
     <ng-container *ngIf="this.currentUser">
          <div  
          [ngClass]="coreConfig.app?.appLanguage=='ar' && coreConfig.layout?.type=='horizontal'  ? 'user-nav d-sm-flex d-none user-nav-rtl' : 'user-nav d-sm-flex d-none'"
          >
            <span id="user-name" class="user-name font-weight-bolder"
              >{{ this.currentUser.name }}</span
            ><span class="user-status">
              {{currentUser.role=='Admin' ? ('admin' | translate)  : currentUser.role=='Instructor'? ('instructor' | translate) : ('student' | translate) }}
            </span>
          </div>
          <span class="avatar"
            >
            
            <img id="user-avatar"
              class="round"
              [src]="this.currentUser.avatar_path?this.currentUser.avatar_path:'/assets/images/no-user-image.jpeg' "
              alt="avatar"
              height="40"
              width="40" />
              
              
              <span class="avatar-status-online"></span
          ></span>
        </ng-container>
      </a>

      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" 
      [ngClass]="isRTL ? 'dropdown-menu text-align-rtl' : 'dropdown-menu dropdown-menu-right'">
      
         <a *ngIf="currentUser.role == 'Instructor' "  ngbDropdownItem [routerLink]="['/instructor/courses/dashboard']">
          <span [data-feather]="'credit-card'" [class]="'mr-50'"></span>
          <span [ngClass]="isRTL ? 'menu-list-margin-rtl' : ''">{{'mycourses' | translate}}</span> 
          </a>
          
     
        <div class="dropdown-divider"></div>
       
        <a  ngbDropdownItem [routerLink]="['/settings/account-settings']"
          ><span [data-feather]="'settings'" [class]="'mr-50 line-height-2'">

          </span> {{'account_setting' | translate}}</a>


          <a *ngIf="currentUser.role == 'Student' " ngbDropdownItem [routerLink]="['/student/account-settings']"
          ><span [data-feather]="'settings'" [class]="'mr-50 line-height-2'">

          </span> {{'account_setting' | translate}}</a>      


          <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50 line-height-2 '"></span> {{'logout' | translate}}</a>
      </div>


      
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
