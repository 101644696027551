<div class="content-wrapper container-xxl overflow-hidden p-0">

  <!-- Card Advance -->
  <div class="card-body row card-limited-height">
    <!-- Congratulations Card -->
    <core-card-snippet class="swiper-container" [snippetCode]="_snippetCodeSwiperAutoplay">
      <swiper class="swiper-autoplay swiper-container carousel-wrapper no-scrollbar" [config]="swiperAutoplay">

        <!-- Slide -->
        <div class="swiper-slide">
          <div class="carousel-slide--content">
            <div class="carousel-slide--content-block">
              <h2 class="font-weight-bold">IET official practice test</h2>
              <p>'IET Progress Check' is an official online practice test marked by IET experts.</p>
              <br>
              <a class="btn btn-danger waves-effect waves-float waves-light" href="#" tabindex="-1">Purchase IET Progress Check today</a>
            </div>

            <div class="carousel-slide--content-img">
              <div>
                <img src="https://www.ielts.org/-/media/cdielts-761.ashx?iar=0&hash=DDD1C258978117572059072611955173"
                  alt="" class="rounded-circle">
                <div class="carousel-slide--gradient"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- Slide -->
        <div class="swiper-slide">
          <div class="carousel-slide--content">

            <div class="carousel-slide--content-block">
              <h2 class="font-weight-bold">New milestone for world-leading English test</h2>
              <p>IET trusted by more than 40 organisations across the globe</p>
              <br>
              <a class="btn btn-danger waves-effect waves-float waves-light" href="#" tabindex="-1">Find out more information</a>
            </div>

            <div class="carousel-slide--content-img">
              <div>
                <img src="assets/images/slides/student.png"
                  alt="" class="rounded-circle">
                <div class="carousel-slide--gradient"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- Slide -->
        <div class="swiper-slide">
          <div class="carousel-slide--content">
            <div class="carousel-slide--content-block">
              <h2 class="font-weight-bold">Malaysia says yes to IET</h2>
              <p>IET is accepted by over 32 institutions in the Malaysia.</p>
              <br>
              <a class="btn btn-danger waves-effect waves-float waves-light" href="#" tabindex="-1">Find out more about IET in the Malaysia</a>
            </div>

            <div class="carousel-slide--content-img">
              <div>
                <img
                  src="assets/images/slides/ukm-university.jpg"
                  alt="" class="rounded-circle">
                <div class="carousel-slide--gradient"></div>
              </div>
            </div>
          </div>
        </div>

      </swiper>
      <div class="swiper-pagination"></div>

    </core-card-snippet>
  </div>




  <!--  -->

  <div class=" card-body row card-limited-height">


        <div class="video-wrapper video-wrapper-single video-wrapper-active" data-youtubeid="vWEbQaoRepo">
          <iframe class="video-player" frameborder="0" allowfullscreen="1" src="https://www.youtube.com/embed/fQEbbUrgBrw"></iframe>
        </div>
    
    
      <div class="col-lg-6 col-xl-6">
         <br>
         <div class="carousel-slide--content-block">
          <h2>What is IET?</h2>
        </div>
            <br />
          <span class="d-flex card-text" style="font-size: 1.3rem;line-height:1.8;">
            IET is an English language test for study or work. Over thirty thousand people take our test every year. IET is accepted by more than 110 employers, universities, schools and works bodies around the world.
          </span>
        
          <br />
    
          <div class="row flex-wrap">
            <div class="col-xl-6 mb-1 custom-btn">
              <button type="button" class="btn btn-white waves-effect  custom-btn-text">Learn more about the test</button>
            </div>
            <div class="col-xl-6 custom-btn">
              <button type="button"  class="btn btn-danger waves-effect waves-float waves-light custom-btn-text">Who accepts IET scores?</button>
            </div>
          </div>
       
      </div>
   
    
  </div>

<br>

  <!--Where can IET take you-->
  <div class=" card user-card pricing-free-trial">
    <br>
    <div class="carousel-slide--content-block">
      <h2 style="text-align: center;">Institutions That Accept IET Certificate</h2>
    </div>
    <br>
<!--  -->

<section id="component-swiper-responsive-breakpoints">
  <core-card-snippet [snippetCode]="_snippetCodeSwiperResponsive">
<br>
    <div class="card-body">
      <div class="swiper-responsive-breakpoints swiper-container" [swiper]="swiperResponsive">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/1.png" alt="IET" />

          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/2.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/3.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/4.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/5.png" alt="IET" />
          </div>

          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/6.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/7.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/8.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/9.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/10.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/11.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/12.png" alt="IET" />
          </div>

          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/14.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/15.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/16.png" alt="IET" />
          </div>
          <div class="swiper-slide">
            <img class="img-fluid" src="assets/images/slides/universities/17.png" alt="IET" />
          </div>





        </div>
        <br>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </core-card-snippet>
</section>
<!--  -->

  </div>

  <!--Where can IET take you-->
  <div class=" card user-card pricing-free-trial">
    <br>
    <div class="carousel-slide--content-block">
      <h2 style="text-align: center;">Where can IET take you</h2>
    </div>
    <br>
    <div>
      <div class="container homepage-section">
        <div class="_icon-block-container">
          <section class="_icon-block">
            <a href="/#" target="">
              <div class="_icon-block__item">
                <div class="item-image">
                  <img
                    src="https://www.ielts.org/-/media/icons/countries/australia.ashx?iar=0&hash=43CE8C7106C80C51B6244547FA64D2EE"
                    alt="">
                </div>
                <div class="item-content">
                  <div class="item-content__title">
                    <h2>Australia</h2>
                  </div>
                  <p></p>
                </div>
              </div>
            </a>
            <a href="$" target="">
              <div class="_icon-block__item">
                <div class="item-image">
                  <img
                    src="https://www.ielts.org/-/media/icons/countries/canada.ashx?iar=0&hash=AB1E3AB4D84E79A32099B56DFC593F00"
                    alt="">
                </div>
                <div class="item-content">
                  <div class="item-content__title">
                    <h2>Canada</h2>

                  </div>
                  <p></p>
                </div>
              </div>

            </a>
            <a href="#" target="">
              <div class="_icon-block__item">
                <div class="item-image">
                  <img
                    src="https://www.ielts.org/-/media/icons/countries/new-zealand.ashx?iar=0&hash=EAD28C8C543554CF0DCD27456781640B"
                    alt="">
                </div>
                <div class="item-content">
                  <div class="item-content__title">
                    <h2>New Zealand</h2>

                  </div>
                  <p></p>
                </div>
              </div>

            </a>
            <a href="#" target="">
              <div class="_icon-block__item">
                <div class="item-image">
                  <img
                    src="https://www.ielts.org/-/media/icons/countries/united-kingdom.ashx?iar=0&hash=814DA7A2CD8CC34A6CB9A7A0A58778D8"
                    alt="">
                </div>
                <div class="item-content">
                  <div class="item-content__title">
                    <h2>United Kingdom</h2>

                  </div>
                  <p></p>
                </div>
              </div>

            </a>
            <a href="/usa" target="">
              <div class="_icon-block__item">
                <div class="item-image">
                  <img
                    src="https://www.ielts.org/-/media/icons/countries/usa.ashx?iar=0&hash=29374D0DCAD599CE953F14D16F7ED131"
                    alt="">
                </div>
                <div class="item-content">
                  <div class="item-content__title">
                    <h2>United States</h2>

                  </div>
                  <p></p>
                </div>
              </div>

            </a>
          </section>

          <a href="" target="" class="btn-primary  _btn _btn-large grey-theme" style="
    min-width: 190px;
    text-align: center;
">IET for study</a>
        </div>
      </div>
    </div>
  </div>


</div>