<!-- Footer -->
<div class="">
  <div class="card-body">
    <div class="row">
      <ul class="footer-links footer-links--partners">
        <li class="footer-item">
            <a href="#">
                <img src="assets/images/logo/bw_ukm.png" width="70%" alt="UKM Logo">
            </a>
        </li>
        <li class="footer-item">
            <a href="#">
                <img src="assets/images/logo/moe.png" width="100%" alt="MOE logo">
              
            </a>
        </li>
        <li class="footer-item">
            <a href="#">
                <img  src="assets/images/logo/ukmshape.png" width="70%" alt="KSBB" title="ksbb">
             
            </a>
        </li>
</ul>

    </div>
  </div>
  <div class="footer-links--logo-statement">IET is jointly owned by UKM; UKMSHAPE; and Ministry of Higher Education Malaysia</div>

  <div class="row">
    <div class="col-xl-4 col-lg-4 col-sm-4 mt-2 mt-xl-0" style="width: 50%!important;">
      <div class="user-info-wrapper">
        <div class="d-flex flex-wrap">
          <p class="card-text mb-0">About IET</p>
        </div>
  
        <div class="d-flex flex-wrap my-50">
          <p class="card-text mb-0">Disclaimer</p>
        </div>
  
        <div class="d-flex flex-wrap my-50">
          <p class="card-text mb-0">Privacy Policy</p>
        </div>
      </div>
    </div>
  
    <div class="col-xl-4 col-lg-4 col-sm-4 mt-2 mt-xl-0"  style="width: 50%!important;">
      <div class="user-info-wrapper">
        <div class="d-flex flex-wrap">
          <p class="card-text mb-0">For Test Takers</p>
        </div>
  
        <div class="d-flex flex-wrap my-50">
          <p class="card-text mb-0">For Organisations</p>
        </div>
  
        <div class="d-flex flex-wrap my-50">
          <p class="card-text mb-0">Contact Us</p>
        </div>
      </div>
    </div>
  </div>
  
  <br>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ 'footer.copyright' | translate }} &copy; {{ year }}
      <a class="ml-25 custom-link" href="iet.edu.my" target="_blank">IET</a>
      <span class="d-none d-sm-inline-block">, {{ 'footer.all_rights_reserved' | translate }}</span>
    </span>
    <span class="float-md-right d-none d-md-block"> {{ 'footer.IET' | translate }} </span>
  </p>
  <!--/ Footer -->
<br>
  <!-- Move to top Button-->
  <app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

 
</div>
