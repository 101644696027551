export const locale = {
  lang: 'en',
  data: {
    auth:{
      email_password_wrong: 'Email or password incorrect',
      please_sign_in: 'Please sign in to your account to start your learning journey',
      welcome_to_classtive: 'Welcome to the ! 👋',
      email: 'Email',
      password: 'Password',
      forget_password: 'Forgot password?',
      remember_me: 'Remember me',
      sign_in: 'Sign in',
      sign_up: 'Sign up',
      new_on_platform: 'Are you new on our ?',
      sign_up_start_learning: 'Sign up and start learning!',
      full_name: 'Full name',
      your_full_name: 'Enter your full name',
      create_an_account: 'Create an account',
      name_required: 'Name is required',
      email_required: 'Email is required',
      confirm_password_required:'Password confirmation is required',
      email_must_valid: 'Email must be a valid address',
      current_password:'Current password',
      current_password_required:'Current password is required',
      old_password_required:'Old password is required',
      new_password_required:'New password is required',
      password_required: 'Password is required',
      email_already_exists: 'Email already exists',
      im_student: 'I am a student',
      im_instructor: 'I am an instructor',
      role_required: 'Role is required',
      privacy_policy_terms: 'Privacy policy and terms',
      you_must_accept_tm: 'You must accept the privacy policy and terms before signing up',
      i_agree: 'I agree to',
      have_account: 'Do you have an account?',
      sign_in_instead: 'Sign in here',
    
    },
    footer:{
      teach_on_courseel: 'Teach on the ',
      aboutus: 'About Us',
      contactus: 'Contact Us',
      terms: 'Terms',
      privacy_policy: 'Privacy Policy',
      help_and_support: 'Help and Support',
      IET: 'IET ',
      copyright: 'Copyright',
      all_rights_reserved: 'All rights reserved',
    
    },
    card: {
      our_mission:'Our mission',
      our_mission_desc:'Our goal is to drive digital progress and establish a sustainable scientific environment for improved knowledge, innovation, and advanced education through technology.',
      how_to_write_thesis: 'How to write a thesis?',
      card_description:'Take the first step towards crafting a high-quality thesis or dissertation through specialized training courses led by seasoned trainers.',
      get_started_now:'Get started now',
      become_an_instructor:'Become an instructor',
      edu_resource:"Educational resources",
      edu_resource_desc:"IET  provides free educational materials in Arabic to support school students of all age groups.",
      become_an_instructor_desc:'Instructors from all over the world teach millions of students on IET. We provide the tools and skills to teach what you love.',
      start_teaching_today:'Start teaching today',
      explore_anataleb:"Explore with IET",
      transform_your_life:'Transform your life through education',
      transform_your_life_desc:'Learners around the world are landing new jobs, advancing in their fields, and enriching their lives.',
      find_out_how:'Find out how',
      },
    


    course: {
      lets_start_learning: 'Let\'s start learning',
      search_for_anything:'Search for anything',
      what_you_learn:'What you\'ll learn',
      created_by:'Created by',
      last_updated:'Last updated',
      go_to_course_content:'Go to course content',
      course_content:'Course content',
      course_materials:'Section materials',
      choose_prefered_method:'Choose your preferred method to learn this section',
      exercise:'Exercise',
      optional:'Optional',
      optional_desc:'Exercise is optional that helps you to gain more understanding',
      assignment:'Assignment',
      minutes_to_complete:'Minutes to complete',
      mandatory:'Mandatory',
      students:'Students',
      courses:'Courses',
      rating:'Rating',
      k:'k',
      questions_comments:'Questions & Comments',
      questions_comments_desc:'Navigate to the other students questions, comments or leave your comment or question about this subject',
      comments:'Comments',
      subject_explanantion:'Explanation of the topic',
      email_instructor:'Email your instructor',
      email_instructor_desc:'Contact the instructor for further understanding',
      email_Communication:'Email Communication',
      download_slide_btn:'Click here to download the slides',
      watch_video_btn:'Click here to start watching the video',
      this_course_includes:'This course includes:',
      
      hours_video:'hours video',
      articles:'articles',
      requirements:'Requirements',
      my_learning:'My Learning',
      click_here_download_file:' Click here to download the file',
      compressed:'Compressed file',
      docs:'Document',
      pdf:'PDF',
      powerpoint:'Powerpoint',
      video:'Video',
      audio:'Audio',
      slides:'slides',
      pages:'pages',
      mints:'mints',

      
    },
    register_by: 'Or register using',
    login: 'Login',
    signup: 'New registration',
    featured: 'Featured',
    free: 'Free',
    admin: 'Admin',
    student: 'Student',
    instructor: 'Instructor',
    home: 'Homepage',
    back_home: 'Return to homepage',
    back_login:'Return to login page',
    send_reset_link:'Send a password reset link',
    account_setting: 'Account settings',
    logout: 'Log out',
    mycourses: 'My courses',
    image_accept: 'JPG, GIF, or PNG format is allowed. Maximum size 800 KB',
    male: 'Male',
    female: 'Female',
    field_required: 'This field is required',
    email_not_confirmed: 'Your email has not been confirmed. Please check your inbox.',
    email_already_sent: 'Activation message has been sent to your email, Please confirm your email address to use Ana Talab ',
    resend_confirmation: 'Resend confirmation',
    resend_confirmation_after: 'To resend confirmation wait ',
    upload: 'Upload',
    reset: 'Reset',
    change_password: 'Change password',
    social: 'Social media pages',
    notifications: 'Notifications',
    general: 'General',
    activity: 'Activities',
    emailـcommentsـcourse: 'Email me when someone comments on my course',
    email_answer_assignment: 'Email me when someone answers my assignments',
    emailـask_join: 'Email me when someone requests to join my page',
    save_changes: 'Save changes',
    cancel: 'Cancel',
    social_links: 'Social media links',
    add_link: 'Add link',
    twitter: 'Twitter',
    facebook: 'Facebook',
    linkedin: 'Linkedin',
    instagram: 'Instagram',
    old_password: 'Old password',
    new_password: 'New password',
    retype_new_password: 'Retype new password',
    name: 'Name',
    email: 'Email',
    mobile:'mobile number',
     jobtitle:'job title',
     bod: 'date of birth',
     country: 'country',
     bio: 'Biography',
     return_to_login:'return to login page',
     send_activation_link_again:'Send activation link again',
     email_verification_failed: 'Email Verification Failed',
     verification_link_invalid_expired:'This verification link is invalid or expired.',
     email_verification_success: 'Your email has been verified successfully',
     redirected_login_page:'You are being redirected to the login page...',
     not_authorized: 'You are not authorized to log in!',
     not_have_privileges: 'Your account does not have sufficient permissions to access this page',
     page_not_found: 'The page does not exist',
     page_not_found_desc: 'Oops! 😖 The requested page was not found.',
     welcome: 'Welcome',
     login_success: 'You have been logged in successfully!',
     begin_education_trip:'Now you can begin your educational journey. Go now! 🎉',
     system_notifications: 'System Notifications',
     new: 'new',
     read_all_notifications:'Read all notifications',
     incorrect_password: 'Password incorrect',
     email_not_found: 'The email does not exist',
     email_already_exists: 'The email already exists',
     city_town:'City/Town',
     education_level: 'Educational Level',
     data_updated_successfully: 'Data updated successfully',
     forgot_password:'Forgot your password?',
     email_reset_instruction:'Enter your email and we will send you instructions to reset your password',
   
     reset_password_email_sent:'Password reset email sent',
     reset_password_email_sent_desc:'You should immediately receive an email allowing you to reset your password. Please be sure to check Spam and Trash if you cannot find the email.',
     password_reset_link_invalid:'Password reset link is invalid',
     link_invalid_expired: 'This link is invalid or expired',
     password_changed_successfully: 'Password changed successfully',
     submit: 'Save',
     password_reset: 'Password reset',
     passwords_not_match: 'Passwords do not match',
     password_too_short:'password too short',
     current_password_incorrect:'Current password is incorrect',
     one_social_link_required:'Please enter at least one link',
    
    
  }
};
