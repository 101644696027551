import { Component, Input } from '@angular/core';

@Component({
  selector: '[core-menu-horizontal-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuHorizontalItemComponent {
  @Input()
  item: any;
  public coreConfig: any;
  public isRTL:boolean;

  ngOnInit(): void {
    this.coreConfig = JSON.parse(localStorage.getItem('config'));
    this.isRTL = false; 
  }
}
