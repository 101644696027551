import { Injectable } from '@angular/core';
import {io} from 'socket.io-client';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';
@Injectable({
  providedIn: 'root'
})
export class socketIncomeService {
  socket = io(environment.apiSocketUrl); //socket that connects to our socket.io server

  constructor(  private _authenticationService: AuthenticationService,) {}


  getIncoming(){

    this.socket.on('schoolConfirmedUpdate', (data) => {
      this.updateUser(data.updatedUser);
    });

    this.socket.on('passNewData', (schoolConfirmed) => {
    console.log(schoolConfirmed);
  });
 }


 updateUser(updatedUser:any) {
  if(updatedUser._id == this._authenticationService.currentUserValue._id)
  {
   this._authenticationService.currentUserValue.schoolConfirmed = updatedUser.schoolConfirmed;
   localStorage.removeItem('currentUser');
   localStorage.setItem('currentUser', JSON.stringify(updatedUser));
  }
}

}