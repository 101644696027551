import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreMenu } from '@core/types/core-menu';
import { CoreCategoryMenu } from '@core/types/category-menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;
  public coreConfig:any;
  currentRoute: string;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor( 
    private router: Router,
    private _changeDetectorRef: ChangeDetectorRef, 
    private _coreMenuService: CoreMenuService) {
      this.currentRoute = ''; // initialize currentRoute here

      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
       
        this.currentRoute = event.url;
      });
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

    categoryMenu:CoreCategoryMenu[] = [];

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  isRouteActive(route: string): boolean {
    return this.currentRoute.startsWith(route);
  }
  /**
   * On init
   */
  ngOnInit(): void {
    
    this.coreConfig = JSON.parse(localStorage.getItem('config'));


    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;

      // Load menu
      this.menu = this._coreMenuService.getCurrentMenu();

      this._changeDetectorRef.markForCheck();
    });
  }
}
