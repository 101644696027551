import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslatePipe } from '@ngx-translate/core';

import { environment } from 'environments/environment';
import { User, Role } from 'app/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;


  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, 
    private _toastrService: ToastrService,
    private translate: TranslatePipe
    ) 
    {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }


  /**
   *  Confirms if user is Student
   */
  get isStudent() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Student;
  }
  /**
   *  Confirms if user is Instructor
   */
   get isInstructor() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Instructor;
  }

  updatePassword(newPassword: string, userId: string): Observable<any> {
    return this._http.put<any>(`${environment.apiUrl}users/update-password/${userId}`, { password: newPassword });
  }

  register(user: User): Observable<User> {
    return this._http.post<User>(`${environment.apiUrl}users/register`, user);
  }

  resetEmail(email: String,lang:String): Observable<any> {
    return this._http.post(`${environment.apiUrl}users/reset-password`, { email: email,lang:lang })
  }

  resendConfirmation(email: String,lang:String): Observable<any> {
    return this._http.post(`${environment.apiUrl}users/resend-activation-code`, { email: email,lang:lang })
  }

  getResendTime(email:string): Observable<any> {
    return this._http.get(`${environment.apiUrl}users/resend-time?email=${email}`);
    }
    
    

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {

    let welcome = this.translate.transform('welcome');
    let login_success = this.translate.transform('login_success');
    let begin_education_trip = this.translate.transform('begin_education_trip');
    return this._http
      .post<any>(`${environment.apiUrl}users/login`, { email, password })
      .pipe(
        map(data => {
          // login successful if there's a jwt token in the response
          if (data && data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(data.user));
            // notify
            this.currentUserSubject.next(data.user);

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                login_success+' ' +
                begin_education_trip,
                '👋 '+welcome+', ' + data.user.name + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
          
         
            }, 1500);

          }

          return data.user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
