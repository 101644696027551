<li ngbDropdown 




[ngClass]="coreConfig?.isRTL? 'nav-item dropdown-notification mr-25 notification-rtl' : 'nav-item dropdown-notification mr-25'"
 
>
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">{{
      notifications.messages?.length + notifications.systemMessages?.length
    }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
 
  [ngClass]="isRTL?'dropdown-menu dropdown-menu-media dropdown-menu-left':'dropdown-menu dropdown-menu-media dropdown-menu-right'"
  
 
  
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div [ngClass]="{'dropdown-header d-flex': true, 'rtl': isRTL}">
       
       
        <h4 [ngClass]="isRTL?'notification-title mb-0 ml-auto':'notification-title mb-0 mr-auto'"
        
        
        >{{'notifications'|translate}}</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ notifications.messages?.length + notifications.systemMessages?.length }} {{'new'|translate}}
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a class="d-flex" href="javascript:void(0)" *ngFor="let message of notifications.messages">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar">
              <img [src]="message.image" alt="avatar" width="32" height="32" />
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="message.heading"></p>
            <small class="notification-text">{{ message.text }}</small>
          </div>
        </div></a
      >
      <div  [ngClass]="{'media d-flex align-items-center': true, 'rtl': isRTL}" >
        <h6 
        [ngClass]="isRTL?'font-weight-bolder ml-auto mb-0':'font-weight-bolder mr-auto mb-0'"
        
        >{{'system_notifications'|translate}}</h6>
        
        <div class="custom-control custom-control-primary custom-switch">
          <input
            class="custom-control-input"
            id="systemNotification"
            type="checkbox"
            [checked]="isRTL"
          />
          <label class="custom-control-label" for="systemNotification"></label>
        </div>

      </div>
      <a class="d-flex" href="javascript:void(0)" *ngFor="let systemMessage of notifications.systemMessages">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
              class="avatar"
              [ngClass]="{
                'bg-light-danger': systemMessage.icon === 'x',
                'bg-light-success': systemMessage.icon === 'check',
                'bg-light-warning': systemMessage.icon === 'alert-triangle'
              }"
            >
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="systemMessage.icon"></i></div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="systemMessage.heading"></p>
            <small class="notification-text">{{ systemMessage.text }}</small>
          </div>
        </div></a
      >
    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" href="javascript:void(0)">{{'read_all_notifications'|translate}}</a>
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>
