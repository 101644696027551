import { Component, OnInit } from '@angular/core';
import * as snippet from 'app/layout/components/extensions/swiper/swiper.snippetcode';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { locale as english } from 'app/layout/page_translate/en';
import { locale as arabic } from 'app/layout/page_translate/ar';
import { CoreTranslationService } from '@core/services/translation.service';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
})

export class LandingPageComponent implements OnInit {
  // public
  public contentHeader: object;
  public shopSidebarToggle = false;
  public shopSidebarReset = false;
  public gridViewRef = true;
  public toggleMenu = true;
  public coreConfig: any;
  public isRTL:boolean;
  public _snippetCodeSwiperAutoplay = snippet.snippetCodeSwiperAutoplay;
  public _snippetCodeSwiperResponsive = snippet.snippetCodeSwiperResponsive;



  public swiperAutoplay: SwiperConfigInterface = {
    spaceBetween: 10,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
  };

  public swiperResponsive: SwiperConfigInterface = {
    slidesPerView: 5,
    spaceBetween: 50,
    // init: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },    
    
    autoplay: {
      delay: 1000,
      disableOnInteraction: true
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 40
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10
      }
    }
  };

  constructor(
    private _coreTranslationService: CoreTranslationService
   
    ) {
      this._coreTranslationService.translate(english, arabic);
    }
   
 
  /**
   * On init
   */
  ngOnInit(): void {

    this.coreConfig = JSON.parse(localStorage.getItem('config'));

    this.isRTL = this.coreConfig?this.coreConfig.isRTL:false; 
 
    }


}
