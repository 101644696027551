export const locale = {
  lang: 'ar',
  data: {
 
    auth:{
      email_password_wrong:'البريد الالكتروني او كلمة المرور غير صحيحة',
      please_sign_in:'الرجاء تسجيل الدخول إلى حسابك لتبدأ رحلة التعلم ',
      welcome_to_classtive:'مرحبًا بك في المنصة! 👋',
      email:'البريد الإلكتروني',
      password:'كلمة المرور',
      confirm_password:'تأكيد كلمة المرور',
      forget_password:'هل نسيت كلمة السر؟',
      remember_me:'تذكرنى',
      sign_in:'تسجيل الدخول',
      sign_up:'تسجيل جديد',
      new_on_platform:'هل انت جديد على منصتنا؟',
      sign_up_start_learning:'سجل وابدأ التعلم!',
      full_name:'الاسم كاملاً',
      your_full_name:'ادخل اسمك بالكامل',

      create_an_account:'انشئ حساب',
      name_required:'الاسم مطلوب',
      email_required:'البريد الإلكتروني مطلوب',
      email_must_valid:'يجب أن يكون البريد الإلكتروني صالحًا',
      current_password:'كلمة المرور الحالية',
      current_password_required:'كلمة المرور الحالية مطلوبة',
      old_password_required:'كلمة المرور القديمة مطلوبة',
      new_password_required:'كلمة المرور الجديدة مطلوبة',

      password_required:'كلمة المرور مطلوبة',
      confirm_password_required:'تأكيد كلمة المرور مطلوب',
      email_already_exists:'البريد الالكتروني موجود بالفعل',
      im_student:'انا طالب',
      im_instructor:'انا معلم',
      role_required:'الدور المطلوب',
      privacy_policy_terms:'سياسة وشروط الخصوصية',
      you_must_accept_tm:'يجب عليك قبول شروط وسياسة الخصوصية قبل التسجيل',
      i_agree:'اوافق على',
      have_account:'هل لديك حساب؟',
      sign_in_instead:'سجل دخول من هنا',
 
    },
    footer:{
      teach_on_courseel:'التدريس على المنصة',
      aboutus:' من نحن',
      contactus:'اتصل بنا',
      terms:'الشروط',
      privacy_policy:'سياسة الخصوصية',
      help_and_support:'المساعدة والدعم',
      IET:'منصة أنا طالب',
      copyright:'حقوق النشر',
      all_rights_reserved:'جميع الحقوق محفوظة',

    },
    card: {
      our_mission:'مهمتنا',
      our_mission_desc:'نتطلع إلى قيادة التحول الرقمي في المستقبل لخلق بيئة علمية مستدامة تقود إلى واقع افضل من المعرفة والابتكار ونقدم تجربة تعليمية مبتكرة تأخذ مجتمع المتعلمين الى بعد آخر من التطور بتطبيق الحلول التكنولوجية على نظام التعليم. ',
      how_to_write_thesis: 'كيف تكتب اطروحة الدكتوراه؟',
      card_description:'اتخذ الخطوة الأولى نحو صياغة أطروحة أو أطروحة أو مشروع بحث عالي الجودة من خلال دورات تدريبية مخصصة من قبل مدربين مهرة.',
      get_started_now:'ابدأ الآن',
      become_an_instructor:'كن معلماً',
      edu_resource:"موارد التعليم المدرسي",
      edu_resource_desc:"توفّر منصة انا طالب للتعليم المدرسي مواد تعليمية مجانية باللّغة العربية لدعم طلبة المدارس من جميع الفئات العمرية.",
      become_an_instructor_desc:'يقوم المدربون من جميع أنحاء العالم بتدريس ملايين الطلاب فيأنا طالب. نحن نقدم الأدوات والمهارات لتعليم ما تحب.',
      start_teaching_today:'ابدأ التدريس اليوم',
      explore_anataleb:"تقدّم مع منصة أنا طالب",
      transform_your_life:'غير حياتك من خلال التعليم',
      transform_your_life_desc:'يطلق المتعلمون في جميع أنحاء العالم وظائف جديدة ، ويتقدمون في مجالاتهم ، ويثري حياتهم.',
      find_out_how:'اكتشف ذلك الآن',
   
    },
    course: {
      lets_start_learning: 'لنبدأ التعلم',
      search_for_anything:'ابحث عن أي شيء',
      what_you_learn:'ما سوف تتعلمه',
      created_by:'انشأ بواسطة',
      last_updated:'اخر تحديث',
      go_to_course_content:'انتقل إلى محتوى الدرس',
      course_content:'محتويات الدرس',
      course_materials:'المادة التعليمية',
      choose_prefered_method:'اختر طريقتك المفضلة لتتعلم موضوع الدرس',
      exercise:'التمرين',
      optional:'اختياري',
      optional_desc:'التمرين اختياري يساعدك على اكتساب المزيد من الفهم',
      assignment:'المهمة',
      minutes_to_complete:'دقيقة لإكمال المهمة',
      mandatory:'مطلوب',
      students:'طلاب',
      courses:'دروس',
      rating:'التقييم',
      k:'الف',
      questions_comments:'الأسئلة والتعليقات',
      questions_comments_desc:'انتقل إلى أسئلة الطلاب الآخرين أو تعليقاتهم أو اترك تعليقك أو سؤالك حول هذا الموضوع',
      comments:'التعليقات',
      subject_explanantion:'شرح الموضوع',
      email_instructor:'تواصل مع المدرس',
      email_instructor_desc:'تواصل مع المدرس لمزيد من الفهم',
      email_Communication:'التواصل عبر البريد الإلكتروني',
      download_slide_btn:'انقر هنا لتحميل الشرائح',
      watch_video_btn:'انقر هنا لبدء مشاهدة الفيديو',
      this_course_includes:'يحتوي الدرس على:',
      hours:'ساعة',
      
      hours_video:'ساعة فيديو',
      
      articles:'مقالات',
      click_here_download_file:' اضغط هنا لتحميل الملف',
      requirements:'المتطلبات',
      my_learning:'دروسي',
      compressed:'ملف مضغوط',
      docs:'ملف وورد',
      powerpoint:'عرض تقدمي',
      video:'فيديو',
      audio:'تسجيل صوتي',
      slides:'شريحة',
      pdf:'PDF',
      pages:'صفحات',
      mints:'دقائق',


    },
     register_by:'او سجل باستخدام',
    login:'دخول',
    signup:'تسجيل جديد',
    featured:'مميز',
    free:'مجاني',
    admin:'مدير',
    student:'طالب',
    instructor:'معلم',
    home:'الصفحة الرئيسية',
    back_home:'العودة للصفحة الرئيسية',
    back_login:'العودة لصفحة تسجيل الدخول',
    send_reset_link:'ارسل رابط اعادة تعيين كلمة المرور',
    account_setting:'اعدادات الحساب',
    logout:'تسجيل خروج',
    mycourses:'دروسي',
    image_accept:'مسموح بتنسيق JPG أو GIF أو PNG. أقصى حجم 800 كيلو بايت',
    male:'ذكر',
    female:'أنثى',
    field_required:'هذا الحقل مطلوب',
    email_not_confirmed:'لم يتم تأكيد بريدك الإلكتروني. يرجى التحقق من البريد الوارد الخاص بك.',
    email_already_sent:'تم ارسال رسالة التفعيل إلى ايميلك يرجى تأكيد عنوان بريدك الإلكتروني لتتمكن من استخدام منصة انا طالب',
    resend_confirmation:'أعد إرسال التأكيد',
    resend_confirmation_after:'لإعادة ارسال التأكيد انتظر ',
    upload:'تحميل',
    reset:'اعادة تعيين',
    change_password:' تغيير كلمة المرور',
    social:'صفحات التواصل الاجتماعي',
    notifications:'الاشعارات',
    general:'عام',
    activity:'الأنشطة',
    emailـcommentsـcourse:'راسلني عندما يعلق شخص ما على درسي',
    email_answer_assignment:'راسلني عندما يجيب شخص ما على التمارين',
    emailـask_join:'راسلني عندما يطلب شخص ما الانضمام الى صفحتي',
    save_changes:'حفظ التعديلات',
    cancel:'الغاء',
    social_links:'روابط صفحات التواصل الاجتماعي',
    add_link:'اضف رابط',
    twitter:'تويتر',
    facebook:'فيسبوك',
    linkedin:'لينكد إن',
    instagram:'إنستغرام',
    old_password:'كلمة المرور القديمة',
    new_password:'كلمة المرور الجديدة',
    retype_new_password:'أعد كتابة كلمة المرور الجديدة',
    name:'الاسم',
    email:'البريد الالكتروني',
    mobile:'رقم الجوال',
    jobtitle:'المسمى الوظيفي',
    bod:'تاريخ الميلاد',
    country:'البلد',
    bio:'السيرة الذاتية',
    return_to_login:'العودة إلى صفحة تسجيل الدخول',
    send_activation_link_again:'ارسل رابط التفعيل مرة اخرى',
    email_verification_failed:'فشل التحقق من البريد الإلكتروني',
    verification_link_invalid_expired:'رابط التحقق هذا غير صالح أو منتهي الصلاحية.',
    email_verification_success:'تم تفعيل بريدك الالكتروني بنجاح',
    redirected_login_page:'جاري تحويلك الى صفحة تسجيل الدخول...',
    not_authorized:'أنك غير مخول بالدخول!',
    not_have_privileges:'لا يمتلك حسابك الصلاحيات الكافية للدخول لهذه الصفحة',
    page_not_found:'الصفحة غير موجودة',
    page_not_found_desc:'عفوًا! 😖 لم يتم العثور على الصفحة المطلوبة.',
    welcome:'مرحباً',
    login_success:'تم تسجيل دخولك بنجاح!',
    begin_education_trip:'الآن يمكنك البدء في رحلتك التعليمية. انطلق الآن! 🎉',
    system_notifications:'إشعارات النظام',
    new:'جديد',
    read_all_notifications:'قراءة كافة الاشعارات',
    //---------------------
    incorrect_password:'كلمة المرور غير صحيحة',
    email_not_found:'البريد الالكتروني غير موجود',
    email_already_exists:'البريد الالكتروني موجود بالفعل',
    city_town:'المدينة/البلدة',
    education_level:'المستوى التعليمي',
    data_updated_successfully:'تم تحديث البيانات بنجاح',
    forgot_password:'نسيت كلمة المرور؟',
    email_reset_instruction:'أدخل بريدك الإلكتروني وسنرسل لك تعليمات لإعادة تعيين كلمة مرورك',
   
    reset_password_email_sent:'تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور',
    reset_password_email_sent_desc:'من المفترض أن تتلقى حالاً بريدًا إلكترونيًا يسمح لك بإعادة تعيين كلمة المرور الخاصة بك. يرجى التأكد من فحص البريد العشوائي والمهملات إذا لم تتمكن من العثور على البريد الإلكتروني.',
    password_reset_link_invalid:'رابط اعادة تعيين كلمة المرور غير صالح',
    link_invalid_expired:'هذا الرابط غير صالح أو منتهي الصلاحية',
    password_changed_successfully:'تم تغيير كلمة المرور بنجاح',
    submit:'حفظ',
    password_reset:'إعادة تعيين كلمة المرور',
    passwords_not_match:'كلمات المرور غير متطابقة',
    password_too_short:'كلمة المرور قصيرة جداً',
    current_password_incorrect:'كلمة المرور الحالية غير صحيحة',
    one_social_link_required:'الرجاء إدخال رابط واحد على الأقل',
  //------------------  
    specialty_category:'متخصص في تدريس',
    country_required:'يجب أن تختار البلد',
    city_required:' اختر فقط من القائمة، حدد مدينتك أو اقرب مدينة إليك',
    specialty_required:'اختر تخصصًا واحدًا أو أكثر',
    education_level_required:'اختر مستواك التعليمي',
    school:'المدرسة',
    grade:'المرحلة الدراسية',
    grade_teaching:'المراحل الدراسية التي ادرسها',
    optional:'اختياري',
    grade_required:'يجب ان تحدد المرحلة الدراسية',
    school_not_available:'لا يوجد مدارس في هذه المنطقة',
    search_for_schools:'جاري البحث عن مدارس في المنطقة',
    some_thing_error:'حدث خطأ ما.. يرجى المحاولة في وقت لاحق',
    school_approval_pending:'في انتظار موافقة المدرسة على انضمامك',
     once_approved:'بمجرد الموافقة ستتمكن من الوصول إلى ميزات المدرسة!',
     get_verified:'المطالبة بالتفعيل',
     school_setting_incomplete:'إعداد المدرسة غير مكتمل - لم يتم التحقق من هويتك في مدرستك',

  }
};
