import { Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';
import { menu_admin } from 'app/menu/menu_admin';
import { menu } from 'app/menu/menu';
import { locale as menuEnglish } from 'app/menu/menu_languages/en';
import { locale as menuArabic } from 'app/menu/menu_languages/ar';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './auth/service';
import { WebsocketService } from './services/websocket.service';
import { socketIncomeService } from './services/socket-income.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  //#1e293b 1e293b
  coreConfig: any;
  userCountry:any;
  menu: any;
  menu_admin: any;
  defaultLanguage: 'en'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'en'; // Set application default language i.e fr
  isRTL:boolean;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _socketIncomingService:socketIncomeService,
    private _authenticationService: AuthenticationService,
    private _websocketService: WebsocketService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private router: Router

  ) {
    // Get the application main menu
    this.menu = menu;
    this.menu_admin = menu_admin;

    // Register the menu to the menu service
    this._coreMenuService.register('main', this.menu);

    // Set the main menu as our current menu
    this._coreMenuService.setCurrentMenu('main');

    // Add languages to the translation service
    this._translateService.addLangs(['en', 'ar']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang('en');

    // Set the translations for the menu
    this._coreTranslationService.translate(menuArabic,menuEnglish);

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------


  sendMessage() {
    this._websocketService.sendMessage("Test Message");
  }

  /**
   * On init
   */

  ngOnInit(): void {

    const request = new XMLHttpRequest();
    request.open('GET', 'https://ipapi.co/json/', false);  // make a synchronous request
    request.send();
    if (request.status === 200) {
      this.userCountry = JSON.parse(request.responseText).country_code;
      this._coreConfigService.setConfig({ app:{appCountry:this.userCountry}  });


      console.log(this.userCountry);
    } else {
      console.error('Failed to get user location:', request.status, request.statusText);
    }


   this._socketIncomingService.getIncoming();

    this.coreConfig = JSON.parse(localStorage.getItem('config'));
    this.isRTL = this.coreConfig?this.coreConfig.isRTL:false;  

    const script = document.createElement('script');
    script.src = this.isRTL?'https://maps.googleapis.com/maps/api/js?key=AIzaSyBfwzRPLOecGfXFglQzccRQJ4nE1_VM2ug&libraries=places&language=ar&callback=initMap':'https://maps.googleapis.com/maps/api/js?key=AIzaSyBfwzRPLOecGfXFglQzccRQJ4nE1_VM2ug&libraries=places&language=en&callback=initMap';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    window['initMap'] = function() {
      // Your initMap code here
    };


    this.router.events.subscribe((e) => {
    
      if (e instanceof NavigationEnd) {
      
        //----------------if users website ---------
        
        if (!this.router.url.includes('admin') 
        && !this.router.url.includes('authentication')
        && !this.router.url.includes('miscellaneous')
        ) {

          localStorage.removeItem('config');
         this._coreConfigService.setConfig({ layout:{type:'horizontal'}  });

          this._coreMenuService.unregister('main');
          this._coreMenuService.register('main', this.menu);
              // Set the main menu as our current menu
          this._coreMenuService.setCurrentMenu('main');
          this._elementRef.nativeElement.classList.remove('vertical-layout', 'vertical-menu-modern');
          this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
         

            if (this.coreConfig.app.appLanguage=='ar') {
              this.document.body.classList.remove('ltr-layout');
              this.document.body.classList.add('rtl-layout');
              this.document.body.classList.add('font-rtl');
              this._coreConfigService.config = {isRTL:true}; 
          }


          if (this.coreConfig.app.appLanguage!='ar') {
          
              this._coreConfigService.config = {isRTL:false}; 
              this.document.body.classList.add('ltr-layout');
              this.document.body.classList.remove('rtl-layout');
              this.document.body.classList.remove('font-rtl');
          }
        }


      }else if(this.router.url.includes('admin')){

        localStorage.removeItem('config');
        this._coreConfigService.setConfig({ layout:{type:'vertical'}  });

        this._coreConfigService.config = {isRTL:false}; 
        this._coreConfigService.config = {layout: {type: 'vertical', }};
        this._coreConfigService.config = {app: {appLanguage: 'en', }};


        this.document.body.classList.add('ltr-layout');
        this.document.body.classList.remove('rtl-layout');
        this.document.body.classList.remove('font-rtl');

   

        this._coreMenuService.unregister('main');
        this._coreMenuService.register('main', this.menu_admin);
        // Set the main menu as our current menu
        this._coreMenuService.setCurrentMenu('main');
        
        
        this._elementRef.nativeElement.classList.remove('horizontal-layout', 'horizontal-menu');
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');

      }


      else if(this.router.url.includes('englishPage')){
        localStorage.removeItem('config');
        this._coreConfigService.setConfig({ layout:{type:'horizontal'}  });
        this._title.setTitle(this.coreConfig.app.appTitle);
        this._coreConfigService.config = {app: {appLanguage: 'en', }};
        this._coreConfigService.config = {isRTL:false}; 
        this.document.body.classList.add('ltr-layout');
        this.document.body.classList.remove('rtl-layout');
        this.document.body.classList.remove('font-rtl');
        this.isRTL = false;
      }

     else if(this.router.url.includes('arabicPage')){
      localStorage.removeItem('config');
      this._coreConfigService.setConfig({ layout:{type:'horizontal'}  });
        this._title.setTitle(this.coreConfig.app.appArTitle);
        this._coreConfigService.config = {app: {appLanguage: 'ar', }};
        this._coreConfigService.config = {isRTL:false}; 
        this.document.body.classList.remove('ltr-layout');
        this.document.body.classList.add('rtl-layout');
        this.document.body.classList.add('font-rtl');
        this.isRTL = true;
      }


    });
  


    // Init wave effect (Ripple effect)
    Waves.init();

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      const appLanguage = this.coreConfig.app.appLanguage || 'en';
      this._translateService.use(appLanguage);

      setTimeout(() => {
        this._translateService.setDefaultLang('en');
        this._translateService.setDefaultLang(appLanguage);
      });

      /**
       * !Fix: ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      // Layout
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'vertical-layout',
        'vertical-menu-modern',
        'horizontal-layout',
        'horizontal-menu'
      );
      // Add class based on config options
      if (this.coreConfig.layout.type === 'vertical') {
      
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
      } else if (this.coreConfig.layout.type === 'horizontal') {
        this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
      }

      this.isRTL = this.coreConfig.isRTL; 

      // Navbar
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'navbar-floating',
        'navbar-static',
        'navbar-sticky',
        'navbar-hidden'
      );

      // Add class based on config options
      if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
        this._elementRef.nativeElement.classList.add('navbar-static');
      } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        this._elementRef.nativeElement.classList.add('navbar-sticky');
      } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
        this._elementRef.nativeElement.classList.add('navbar-floating');
      } else {
        this._elementRef.nativeElement.classList.add('navbar-hidden');
      }

      // Footer
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

      // Add class based on config options
      if (this.coreConfig.layout.footer.type === 'footer-sticky') {
        this._elementRef.nativeElement.classList.add('footer-fixed');
      } else if (this.coreConfig.layout.footer.type === 'footer-static') {
        this._elementRef.nativeElement.classList.add('footer-static');
      } else {
        this._elementRef.nativeElement.classList.add('footer-hidden');
      }

      // Blank layout
      if (
        this.coreConfig.layout.menu.hidden &&
        this.coreConfig.layout.navbar.hidden &&
        this.coreConfig.layout.footer.hidden
      ) {
        this._elementRef.nativeElement.classList.add('blank-page');
        // ! Fix: Transition issue while coming from blank page
        this._renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
          'style',
          'transition:none'
        );
      } else {
        this._elementRef.nativeElement.classList.remove('blank-page');
        // ! Fix: Transition issue while coming from blank page
        setTimeout(() => {
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
            'style',
            'transition:300ms ease all'
          );
        }, 0);
        // If navbar hidden
        if (this.coreConfig.layout.navbar.hidden) {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }
        // Menu (Vertical menu hidden)
        if (this.coreConfig.layout.menu.hidden) {
          this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
        } else {
          this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
        }
        // Footer
        if (this.coreConfig.layout.footer.hidden) {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }
      }

      // Skin Class (Adding to body as it requires highest priority)
      if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
        this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      }
    });

     // LTR Class (Adding to body as it requires highest priority)
     if (this.coreConfig.app.appLanguage !== 'ar') {
      this.document.body.classList.remove('rtl-layout');
      this.document.body.classList.remove('font-rtl');
      this.document.body.classList.add('ltr-layout');
    }
    else if (this.isRTL) {
      this.document.body.classList.remove('ltr-layout');
      this.document.body.classList.add('rtl-layout');
      this.document.body.classList.add('font-rtl');
    }
    //----Admin Dashboard
    else if (this.coreConfig.app.appLanguage == 'ar' && this.coreConfig.layout.type=='vertical') {
      this._coreConfigService.config = {app: {appLanguage: 'en', }};
      this.document.body.classList.remove('rtl-layout');
      this.document.body.classList.remove('font-rtl');
      this.document.body.classList.add('ltr-layout');
     
    }


    // Set the application page title
    this.isRTL?this._title.setTitle(this.coreConfig.app.appArTitle):this._title.setTitle(this.coreConfig.app.appTitle);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }


}
