import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import * as countriesLib from 'i18n-iso-countries';
import { User } from 'app/models/user';


// Support french & english languages.

@Injectable()
export class AccountSettingsService implements Resolve<any> {
  instructor: any;
  onSettingsChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient,
           private _insturctorService:AuthenticationService) {
    // Set the defaults
    this.onSettingsChanged = new BehaviorSubject({});
    countriesLib.registerLocale(require('i18n-iso-countries/langs/en.json'));
    countriesLib.registerLocale(require('i18n-iso-countries/langs/ar.json'));
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getUserDetails()]).then(() => {
        resolve();
      }, reject);
    });
  }

    getCountries(lang:string): { id: string; name: string;  }[] {
      return Object.entries(countriesLib.getNames(lang, { select: 'official' })).map((entry) => {
        return {
          id: entry[0],
          name: entry[1]
        };
      });
    }


    getCountry(countryKey: string): string {
      return countriesLib.getName(countryKey, 'en');
    }

    getCountryByLang(countryKey: string,lang:string): string {
      return countriesLib.getName(countryKey, lang);
    }
 
//------

getEducationLevels(lang: string): Observable<any> {
  return this._httpClient.get<any>(`${environment.apiUrl}education-levels?language=${lang}`);
}

//------
getGrades(lang: string): Observable<any> {
  return this._httpClient.get<any>(`${environment.apiUrl}grades?language=${lang}`);
}

//------ Update Password ----------
updatePassword(oldPassword:string, newPassword: string, userId: string): Observable<any> {
  return this._httpClient.put<any>(`${environment.apiUrl}users/update-setting-password/${userId}`, { oldPassword: oldPassword ,newPassword: newPassword });
}

  //------Update general info
  updateSocialAndNotification(userData: any, userId:string): Observable<User> {
    return this._httpClient.put<User>(`${environment.apiUrl}users/update-user/${userId}`,userData);
  }

  //------Update general info
  updateGeneralInfo(userData: FormData, userId:string): Observable<User> {
    return this._httpClient.put<User>(`${environment.apiUrl}users/update-user/${userId}`,userData);
  }
  /**
   * Get rows
   */
  getUserDetails(): Promise<any[]> {
    return new Promise((resolve) => {
      this.instructor = this._insturctorService.currentUserValue;
      this.onSettingsChanged.next(this.instructor);
      resolve(this.instructor);
    });
  }
}
