<!-- Vertical Menu -->
<ng-container *ngIf="menuType === 'vertical-menu'">
  <vertical-menu></vertical-menu>
</ng-container>
<!--/ Vertical Menu -->


<!-- Horizontal Menu -->
<ng-container *ngIf="menuType === 'horizontal-menu'">
  
  <horizontal-menu>
    
  </horizontal-menu>
  
</ng-container>

<!--/ Horizontal Menu -->
